// REFACTOR: please DRY me up

export interface CardImageAttrs {
  cloudflareId?: string;
  unsplashPhoto?: {
    urls: {
      regular: string;
    };
  };
}
// public is the default, vertical-card could be for the course card background,
// then we could have others too, like product thumbnail(small, large), cover, etc
export type CardImageVariant =
  | 'public'
  | 'vertical-card'
  | 'fit=scale-down'
  | 'future_variants...';

export function cardImageUrl(
  image: CardImageAttrs | undefined,
  variant: CardImageVariant = 'public'
): string | undefined {
  if (!image) {
    return undefined;
  } else if (image.cloudflareId) {
    return `https://imagedelivery.net/41eGBrMIml4goV0L9eI4Ww/${image?.cloudflareId}/${variant}`;
  } else if (image.unsplashPhoto) {
    return image.unsplashPhoto.urls.regular;
  } else {
    return undefined;
  }
}

export function cloudflareIDFromUrl(image?: string) {
  if (!image) {
    return;
  }
  return image?.split('/')[4];
}
